<template>
    
    <div class="container flex flex-col items-center justify-center p-5 mx-auto my-10 md:my-72">
         <p class="text-3xl font-semibold md:text-6xl">Bientôt disponible</p>
        <p class="pt-3">Veuillez passer par l'application mobile pour ce service</p>
        <a class="underline" href="/">Accueil</a>
    </div>
   
 <Download/> 
</template>
<script>
import Download from '@/components/Downloads.vue'
export default {
    components: {
        Download
    }
}
</script>